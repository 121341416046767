ul {

}

.pagination li {
    cursor: pointer;
    padding: 2px 8px !important;
    margin: 3px;
    background: white;
    /*-webkit-box-shadow: 2px 2px 6px -1px rgba(194,194,194,0.67);*/
    /*-moz-box-shadow: 2px 2px 6px -1px rgba(194,194,194,0.67);*/
    /*box-shadow: 2px 2px 6px -1px rgba(194,194,194,0.67);*/
    display:inline-block;
    border: 1px solid #dae0e5;

}

.pagination li.active {
    font-weight: bold;
    color: #007bff;
}

.pagination li.active a{
    font-size:16px!important;
}

.pagination li:hover{
    background: #f7f7f7;
}


.list-center {
    display: inline-block!important;
}

.margin-10 {
    margin:10px!important;
}
